<template>
  <span>
      <h1 class="headline blue--text text-center mt-4 mb-3">Credio liikevaihto</h1>
      <v-divider></v-divider>
      Valitse päivämäärä:
      <v-row>
          <v-col cols="2">
            <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
          </v-col>
          <v-col cols="2">
              <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
          </v-col>
          <v-col cols="1">
              <v-btn
                      class="v-btn ma-3 green float-right"
                      @click="getResource"
                      small
              >
                  HAE
              </v-btn>
          </v-col>
      </v-row>
      <span v-if="!resourceLoading">
        <v-card>
            <v-card-text>
                <v-simple-table>
                    <thead>
                        <tr>
                          <th class="text-left">
                            Tyyppi
                          </th>
                          <th class="text-left">
                            Kustannuspaikka
                          </th>
                          <th class="text-left">
                            Nimi
                          </th>
                          <th class="text-left">
                            Prepaid likevaihto (Alv0%)
                          </th>
                          <th class="text-left">
                            Prepaid liikevaihto (Alv25.5%)
                          </th>
                          <th class="text-left">
                            Liikevaihto (Alv0%)
                          </th>
                          <th class="text-left">
                            Liikevaihto (Alv25.5%)
                          </th>

                          <th class="text-left">
                            laskutettu (Alv0%)
                          </th>
                          <th class="text-left">
                            Laskutettu (Alv25.%)
                          </th>

                        </tr>
                      </thead>
                    <tbody>
                    <tr v-for="item in resource.data"
                        :key="item.name"
                    >
                        <td>{{ item.type }}</td>
                        <td>{{ item.kustannus_paikka }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.turnover_prepaid_vat0 }}</td>
                        <td>{{ item.turnover_prepaid_vat255 }}</td>
                        <td>{{ item.turnover_vat0 }}</td>
                        <td>{{ item.turnover_vat255 }}</td>
                        <td>{{ item.billed_vat0 }}</td>
                        <td>{{ item.billed_vat255 }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <v-card class="mt-2">
            <v-card-text>
                <v-row>
                    <v-col>
                        <b>Yhteensä:</b><br/>
                        Alv0%: {{ resource.total_vat0 }}<br/>
                        Alv25.5%: {{resource.total_vat255 }}
                    </v-col>
                    <v-col>
                        <b>Laskutettu:</b><br/>
                        Alv0%: {{ resource.total_billed_vat0 }}<br/>
                        Alv25.5%: {{ resource.total_billed_vat255 }}
                    </v-col>
                    <v-col>
                        <b>Yhteensä prepaid:</b><br/>
                        Alv0%: {{ resource.total_prepaid_vat0 }}<br/>
                        Alv25.5%: {{ resource.total_prepaid_vat255 }}
                    </v-col>

                    <v-col>
                        <b>Jako:</b><br/>
                        KP1: {{ resource.kp1 }}%<br/>
                        KP2: {{ resource.kp2 }}%
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
      </span>
      <v-skeleton-loader v-else>
          Ladataan
      </v-skeleton-loader>
  </span>
</template>

<script>
import moment from "moment";
export default {
    name: "CredioReporting",
    data: function () {
        return {
            startDate: null,
            endDate: null,
            copied: false,
            resourceLoading: false,
            resource: [],
        }
    },
    methods:{
        async getResource(){
            this.resourceLoading = true
            this.copied = false
            this.resource = []
            await this.axios.get('reports/credio_turnover_report', {
                params:{
                  from: this.startDate,
                  to: this.endDate,
                  include_test: 0,
                }
            }).then((resources) => {
                this.resource = resources.data
            }).catch ((error) => {
                console.error(error);
            });
            this.resourceLoading = false
        },
    },
    mounted(){
        const prevMonth = moment().subtract(1, 'months');
        this.startDate = prevMonth.startOf('month').format('DD.MM.YY');
        this.endDate = prevMonth.endOf('month').format('DD.MM.YY');
        ///api/reports/agents/prepaid_report?from=01.05.23&to=31.05.23&include_test=0
        this.getResource()
    }

}
</script>

<style scoped>

</style>